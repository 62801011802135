import { ReactElement } from 'react';

import { getColorByScore } from 'utils/functions/grades';

type Props = {
  cy: number;
  cx: number;
  payload: { score: number };
  offset?: number;
};

const RADIUS = 4;

export const CustomDot = ({ cy, cx, payload, offset = 0 }: Props): ReactElement<SVGElement> => {
  const adjustedCx = cx + offset;

  return (
    <circle cx={adjustedCx} cy={cy} data-testid='custom-dot-circle' fill={getColorByScore(payload.score)} r={RADIUS} />
  );
};
