import { FC } from 'react';

import styles from './TooltipFormatter.module.scss';

import { JitText } from 'components/JitText/JitText';

type Props = {
  label: string;
  count: number;
};

export const TooltipFormatter: FC<Props> = ({ label, count }: Props) => (
  <div className={styles.tooltipWrapper}>
    <JitText muted size='xs' text={label} />

    <JitText text={count} />
  </div>
);

