import { useMemo } from 'react';

import { calculateGradientStops, sliceGradientArray } from './utils';

interface DayData {
  date: string;
  score: number | null;
}

export const useOrgScoreWidget = (daysData: DayData[]) => {
  const gradientColors = useMemo(() => {
    const scores = daysData.filter((day) => day.score !== null).map((day) => day.score!);
    if (scores.length === 0) return [];

    const gradientValues = calculateGradientStops(scores);
    return sliceGradientArray(scores, gradientValues);
  }, [daysData]);

  const customTicks = useMemo(() => {
    const monthIndices: { [key: string]: number[] } = {};
    daysData.forEach((day, index) => {
      const month = new Date(day.date).toLocaleString('default', { month: 'short' });
      if (!monthIndices[month]) {
        monthIndices[month] = [];
      }
      monthIndices[month].push(index);
    });

    const ticksIndices: { [key: number]: string } = {};
    Object.entries(monthIndices).forEach(([month, indices]) => {
      const middleIndex = Math.floor(indices.length / 2);
      ticksIndices[indices[middleIndex]] = month;
    });

    return ticksIndices;
  }, [daysData]);

  const customTickFormatter = (value: number, index: number) => customTicks[index] || '';

  return {
    gradientColors,
    customTickFormatter,
  };
};
