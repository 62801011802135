import { FC } from 'react';
import { useQuery } from 'react-query';

import { RisksBreadcrumbs } from '../components/RisksBreadcrumbs/RisksBreadcrumbs';

import { ResourceBreakdownGrid } from './components/ResourceBreakdownGrid/ResourceBreakdownGrid';
import { ResourcesDashboardTitle } from './components/Title/ResourcesDashboardTitle';

import { JitText } from 'components/JitText/JitText';
import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { useGraphContext } from 'context/GraphContext/GraphContext';
import styles from 'pages/Resources/ResourcesDashboardPage/ResourcesDashboardPage.module.scss';
import { useAssetService } from 'services/AssetsService/useAssetService';
import { Queries } from 'types/enums/Queries';

export enum AssetAggregationKey {
  AssetType = 'asset_type',
  PriorityFactors = 'priority_factors',
}

const DISPLAYED_FACTORS = ['Production', 'Database Integration', 'Externally Accessible', 'Business Critical'];

export const ResourcesDashboardPage: FC = () => {
  const { getAssetsStatistics } = useAssetService();
  const { allPriorityFactors, isLoadingPriorityFactors } = useGraphContext();

  const { data: priorityFactorsStatistics = [], isLoading, isError } = useQuery(
    [Queries.AssetsStatistics, { aggregationKey: AssetAggregationKey.PriorityFactors }],
    getAssetsStatistics,
  );

  if (isLoading || isLoadingPriorityFactors) {
    return <LoadingBar />;
  }

  if (isError) {
    // this is temporary error message, should be replaced with proper error component
    const message = 'Failed to fetch assets statistics';
    return <div className={styles.errorMessage}>{message}</div>;
  }

  const totalCount = priorityFactorsStatistics?.reduce((acc, resource) => acc + resource.count, 0) || 0;

  const priorityFactorsKeysToDisplay = DISPLAYED_FACTORS.map(
    (key) => ({
      key,
      description: allPriorityFactors.find((factor) => factor.key === key)?.description || key,
    }),
  );

  return (
    <div className={styles.wrapper}>
      <RisksBreadcrumbs />

      <ResourcesDashboardTitle resourcesCount={totalCount} />

      <div className={styles.section}>
        <JitText data-testid='priorityFactors-section' size='m' text='pages.risks.resources.resourcesDashboard.sections.priorityFactors' />

        <ResourceBreakdownGrid keysToDisplay={priorityFactorsKeysToDisplay} resourcesStatistic={priorityFactorsStatistics} />
      </div>
    </div>
  );
};
