/* eslint-disable react/no-unstable-nested-components */
import { FC } from 'react';
import { Tooltip, XAxis, YAxis, ResponsiveContainer, Line, LineChart } from 'recharts';

import { CustomDot } from '../components/CustomDot/CustomDot';
import { GraphTooltip } from '../components/GraphTooltip/GraphTooltip';
import { ScoreTooltipFormatter } from '../components/TooltipFormatter/ScoreTooltipFormatter';

type Point = {
  date: string;
  score: number | null;
};
type GradientColor = {
  color: string;
  offset: string;
};

type Props = {
  data: Point[];
  gradientColors: GradientColor[];
  customTickFormatter?: (value: number, index: number) => string;
};

const gradientId = 'lineGradient';

export const JitLinearGradientChart: FC<Props> = ({ data, gradientColors, customTickFormatter }) => (
  <ResponsiveContainer height={200} width='100%'>
    <LineChart data={data}>
      <defs>
        <linearGradient id={gradientId} x1='0' x2='0' y1='0' y2='1'>
          {gradientColors?.map((stopColor) => (
            <stop key={stopColor.offset} offset={stopColor.offset} stopColor={stopColor.color} />
          ))}
        </linearGradient>
      </defs>

      <XAxis
        axisLine={false}
        dataKey='date'
        tickFormatter={customTickFormatter}
        tickLine={false}
      />

      <YAxis hide />

      <Tooltip
        content={({ active, payload }) => (
          <GraphTooltip active={active} payload={payload}>
            <ScoreTooltipFormatter payload={payload} />
          </GraphTooltip>
        )}
        cursor={false}
        wrapperStyle={{ outline: 'none' }}
      />

      <Line
        activeDot={CustomDot}
        dataKey='score'
        dot={false}
        stroke={`url(#${gradientId})`}
        strokeWidth={3}
        type='monotone'
      />
    </LineChart>
  </ResponsiveContainer>
);
