import ELK, { ElkNode } from 'elkjs';
import { Node } from 'reactflow';

import { CustomEdge } from '../CustomEdge';
import { CustomNode } from '../CustomNode';
import { GraphEntity, CustomNodeType, CustomNodeData, CustomEdgeType } from '../types';

import { iconsMap } from './iconsMap';

import colors from 'themes/colors.module.scss';
import { IEntityGraphEdge, IEntityGraphNode } from 'types/interfaces/Graph/IEntityGraph';

export const NODE_HEIGHT = 50;
export const EXTRA_HEIGHT_NODE_WITH_FACTOR = 25;
export const NODE_WIDTH = 200;

export const buildEdge = (edge: IEntityGraphEdge): CustomEdgeType => ({
  ...edge,
  data: {
    description: edge.description,
  },
  type: 'customEdge',
  style: {
    stroke: colors.priorityFactorGraphEdgeStroke,
    strokeWidth: 2,
    opacity: 0.5,
  },
});

export const buildNode = (node: IEntityGraphNode, entity: GraphEntity): CustomNodeType => ({
  ...node,
  type: 'customNode',
  draggable: false,
  data: {
    ...node,
    entity,
    icon: iconsMap[node.type as keyof typeof iconsMap] || {
      icon: () => null,
      bgColor: 'gray',
    },
    metadata: node.metadata,
  },
  position: {
    // The position is calculated by the layout algorithm, so just a placeholder
    x: 0,
    y: 0,
  },
  height: NODE_HEIGHT + EXTRA_HEIGHT_NODE_WITH_FACTOR * (node?.factors?.length || 0),
  width: NODE_WIDTH,
});

export const nodeTypes = {
  customNode: CustomNode,
};

export const edgeTypes = {
  customEdge: CustomEdge,
};

export const getLayoutedElements = async (
  nodes: CustomNodeType[],
  edges: CustomEdgeType[],
): Promise<{
  nodes: CustomNodeType[];
  edges: CustomEdgeType[];
}> => {
  const elk = new ELK();
  const maxFactors = Math.max(...nodes.map((node) => node.data.factors?.length || 1));
  const layout = await elk.layout({
    id: 'root',
    layoutOptions: {
      'elk.algorithm': 'layered', // Use 'layered' for hierarchical layout
      'elk.direction': 'RIGHT', // Direction of the layout, can be 'RIGHT', 'DOWN', 'UP', or 'LEFT'
      'elk.spacing.nodeNode': (EXTRA_HEIGHT_NODE_WITH_FACTOR * (maxFactors + 1)).toString(), // Vertical spacing between nodes
      'elk.layered.spacing.nodeNodeBetweenLayers': '150', // Horizontal spacing between different layers
      'elk.layered.nodePlacement.strategy': 'NETWORK_SIMPLEX', // Strategy for node placement
    },
    children: nodes as ElkNode[],
    edges: edges.map((edge) => ({
      id: edge.id,
      sources: [edge.source],
      targets: [edge.target],
    })),
  });

  const hasVerticalNodes = layout.children!.some((node: ElkNode) => {
    const x = node.x!;
    return layout.children!.some((otherNode: ElkNode) => otherNode.x === x && otherNode.id !== node.id);
  });

  const layoutedNodes = layout.children!.map((node: ElkNode, index: number) => {
    const xPos = node.x! - NODE_HEIGHT / 2;
    let yPos;

    if (hasVerticalNodes) {
      // nodes will be placed on the same y position - inlined
      yPos = node.y!;
    } else {
      // nodes will be placed on alternating y positions - wave-like
      yPos = index % 2 === 0 ? 0 : 30;
    }

    return {
      ...node,
      position: {
        x: xPos,
        y: yPos,
      },
    };
  }) as Node<CustomNodeData>[];

  return {
    nodes: layoutedNodes,
    edges,
  };
};
