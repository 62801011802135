import { useCallback } from 'react';
import { QueryFunctionContext } from 'react-query';

import { getApiUrls } from 'services/apiUrls';
import { useClient } from 'services/useClient';
import {
  IAggregatedAssetStatistics,
  IAggregatedAssetStatisticsSnakeCase,
  IAsset,
  IUpdateAssetRequest,
  IInitialScanStats,
  IFilter,
  IFilterOption,
  IDictionary,
  AssetSortBy,
} from 'types/interfaces';
import { ICount, IGroupByCount } from 'types/interfaces/Counts/ICounts';
import { IAssetFilterValues } from 'types/interfaces/IAssetFilterValues';
import { IPaginatedResponse } from 'types/interfaces/IPaginatedResponse/IPaginatedResponse';
import { camelizeSnakeCaseKeys, snakeCaseCamelizeKeys } from 'utils/functions/camelCaseConverter';

type GetAssetCountParams = {
  groupBy?: string;
  filters?: IDictionary<boolean | string>;
};

export const filtersToRequestParams = (filters: IFilter[], joinArrays: boolean) => {
  const extractParam = (entityKey: keyof IAssetFilterValues) => {
    const filter = filters.find((filterItem) => filterItem.entityKey === entityKey);
    if (!filter) return undefined;
    const values = (filter.selectedValue as IFilterOption[]).map((option) => option.value);
    if (joinArrays) {
      return values.join(',');
    }
    return values;
  };

  return {
    is_covered: true,
    team: extractParam('teams'),
    asset_type: extractParam('asset_types'),
    priority_factors: extractParam('priority_factors'),
  };
};

export const useAssetService = () => {
  const { client } = useClient();
  const getAssets = useCallback(async (teamName?: string) => {
    const url = getApiUrls.assetService.getAssets();
    return client.get<IAsset[]>(
      {
        url,
        requestConfig: {
          params: {
            team: teamName,
          },
        },
        allowedStatuses: [200, 404],
      },
    );
  }, [client]);
  const getAssetsFilterValues = useCallback(async (): Promise<IAssetFilterValues | undefined> => {
    const response = await client.get<IAssetFilterValues>(
      {
        url: getApiUrls.assetService.getAssetsFilterValues(),
        allowedStatuses: [200],
        requestConfig: {
          params: {
            is_active: true,
            is_covered: true,
          },
        },
      },
    );
    if (response?.status === 200) {
      return response.data as IAssetFilterValues;
    }
    throw new Error('Error fetching assets filter values');
  }, [client]);

  interface ISortParams {
    sort_by: AssetSortBy;
    sort_order: string;
  }

  const getAssetsWithPagination = useCallback(async ({
    queryKey,
    pageParam,
  }: QueryFunctionContext<[string, IFilter[], ISortParams]>): Promise<IPaginatedResponse<IAsset> | undefined> => {
    const [, filterParams, sortParams] = queryKey;
    const response = await client.get<IPaginatedResponse<IAsset>>(
      {
        url: getApiUrls.assetService.getAssets(),
        allowedStatuses: [200],
        requestConfig: {
          headers: {
            API_VERSION: 'v2',
          },
          params: {
            ...filtersToRequestParams(filterParams, true),
            after: pageParam,
            limit: 20,
            ...sortParams,
          },
        },
      },
    );
    if (response?.status === 200) {
      return response.data as IPaginatedResponse<IAsset>;
    }
    throw new Error('Error fetching assets');
  }, [client]);

  const updateMultipleAssets = useCallback(async (assets: IAsset[]) => {
    const url = getApiUrls.assetService.updateMultipleAssets();
    const assetsPatch: IUpdateAssetRequest[] = assets.map((asset) => ({
      asset_id: asset.asset_id,
      new_name: asset.asset_name,
      is_covered: asset.is_covered,
      tags: asset.tags,
    }));
    return client.post<IAsset[]>({
      url,
      allowedStatuses: [200, 404],
      requestConfig: {
        data: assetsPatch,
      },
    });
  }, [client]);

  type GetAssetsStatisticsParams = {
    aggregationKey?: string;
    filterParams?: IFilter[];
  };
  const getAssetsStatistics = useCallback(
    async ({
      queryKey,
    }: QueryFunctionContext<[string, GetAssetsStatisticsParams]>): Promise<IAggregatedAssetStatistics[] | undefined> => {
      const [, params] = queryKey;
      const { filterParams, aggregationKey } = params;

      const response = await client.get<IAggregatedAssetStatisticsSnakeCase[]>({
        url: getApiUrls.assetService.getAssetsStatistics(),
        allowedStatuses: [200],
        requestConfig: {
          params: {
            aggregation_key: aggregationKey,
            filters: filtersToRequestParams(filterParams || [], true),
          },
        },
      });

      if (response?.status === 200) {
        return camelizeSnakeCaseKeys(response.data) as IAggregatedAssetStatistics[];
      }
      return undefined;
    },
    [client],
  );

  const getInitialScanStats = useCallback(async () => {
    const url = getApiUrls.assetService.getInitialScanStats();
    const response = await client.get<IInitialScanStats[]>({
      url,
      allowedStatuses: [200],
    });

    if (response?.status === 200) {
      return camelizeSnakeCaseKeys(response.data) as IInitialScanStats[];
    }

    return undefined;
  }, [client]);

  const fetchAssetsCount = useCallback(async (params: GetAssetCountParams) => {
    const url = getApiUrls.assetService.getAssetsCount();
    const response = await client.get<ICount | IGroupByCount[]>({
      url,
      allowedStatuses: [200],
      requestConfig: {
        params: snakeCaseCamelizeKeys(params),
      },
    });

    if (response?.status === 200) {
      return response.data;
    }

    throw new Error('Error fetching assets count');
  }, [client]);

  return {
    getAssets,
    getAssetsWithPagination,
    updateMultipleAssets,
    getAssetsStatistics,
    getAssetsFilterValues,
    getInitialScanStats,
    fetchAssetsCount,
  };
};
