import { useState } from 'react';

import { IPosition } from 'types/interfaces';

export const useGraphTooltip = () => {
  const [activeKey, setActiveKey] = useState('');
  const [position, setPosition] = useState<IPosition>({
    x: 0,
    y: 0,
  });

  const handleMouseMove = (e: React.MouseEvent) => {
    const x = e.nativeEvent.offsetX;
    const y = e.nativeEvent.offsetY - 80;

    setPosition({ x,
      y });
  };

  const handleMouseEnter = (key: string) => setActiveKey(key);
  const handleMouseLeave = () => setActiveKey('');

  return {
    position,
    activeKey,
    handleMouseMove,
    handleMouseEnter,
    handleMouseLeave,
  };
};
