import { t } from 'i18next';
import { FC } from 'react';
import { useQuery } from 'react-query';

import { getMonthlyData } from '../../../utils';

import styles from './FixedIssuesWidget.module.scss';

import { JitBarChart } from 'components/graphs/BarChart/JitBarChart';
import { GraphEmpty, GraphLoading } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/components';
import { fetchFindingsMetric } from 'services/MetricService/MetricService';
import colors from 'themes/colors.module.scss';
import { TimeAgo } from 'types/enums';
import { Queries } from 'types/enums/Queries';
import { FixedIssuesMetric } from 'types/interfaces/Metrics/FindingsMetrics/FindingsMetrics';

type Props = {
  period: TimeAgo;
  breakdown: string;
};

const bars = [
  {
    key: 'preProduction',
    label: t('pages.securityImpactV2.sections.performance.widgets.fixedIssues.bars.preProduction'),
    color: colors.successMain,
  },
  {
    key: 'production',
    label: t('pages.securityImpactV2.sections.performance.widgets.fixedIssues.bars.production'),
    color: colors.successDark,
  },
];

export const FixedIssuesWidget: FC<Props> = ({ period, breakdown = 'environment' }) => {
  const { data, isLoading, isError } = useQuery([Queries.FindingsCount, period, breakdown], () => fetchFindingsMetric<FixedIssuesMetric>({
    period,
    params: { breakdown },
  }));

  if (isLoading) return <GraphLoading />;

  if (isError || !data?.data?.days.length) return <GraphEmpty />;

  const monthlyData = getMonthlyData(data.data.days);

  return (
    <div className={styles.wrapper} data-testid='fixedIssuesWidget'>

      <JitBarChart bars={bars} data={monthlyData} />

    </div>
  );
};
