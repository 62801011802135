import { FC, useMemo } from 'react';
import { useQuery } from 'react-query';

import styles from './OrgScoreWidget.module.scss';
import { useOrgScoreWidget } from './useOrgScoreWidget';
import { getTotalScore } from './utils';

import { JitLinearGradientChart } from 'components/graphs/LinearGradientChart/JitLinearGradientChart';
import { JitText } from 'components/JitText/JitText';
import { GraphEmpty, GraphLoading } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/components';
import { getScoreTrendMetric } from 'services/MetricService/MetricService';
import { Queries } from 'types/enums/Queries';
import { ScoreEntityType } from 'types/interfaces/Metrics/TeamsPortalMetrics/ScoreTrending';
import { getGradeByScore } from 'utils/functions/grades';

const type = ScoreEntityType.Org;

export const OrgScoreWidget: FC = () => {
  const { data, isLoading } = useQuery([Queries.OrgScoreTrending, type], () => getScoreTrendMetric({
    params: {
      entities_type: type,
    },
  }));

  const normalizedScoreData = useMemo(() => data?.entitiesScores?.[0]?.scoresByDay?.map(({ date, score }) => ({
    date,
    score,
  })) || [], [data]);

  const { gradientColors, customTickFormatter } = useOrgScoreWidget(normalizedScoreData);

  const { orgTotalScore, orgGrade } = useMemo(() => {
    const scoresByDay = data?.entitiesScores?.[0]?.scoresByDay.map(({ score }) => score) || [];
    const totalScore = getTotalScore(scoresByDay);
    const grade = getGradeByScore(totalScore);

    return {
      orgTotalScore: totalScore,
      orgGrade: grade,
    };
  }, [data]);

  if (isLoading) return <GraphLoading />;
  if (!normalizedScoreData.length) return <GraphEmpty />;

  return (
    <div className={styles.orgScoreContainer}>
      <div className={styles.score}>
        <JitText bold data-testid='org-score-grade' size='xhuge' text={orgGrade} />

        <JitText data-testid='org-score-percentage' size='xhuge' text={`${orgTotalScore}%`} />
      </div>

      <JitText muted size='xs' text='pages.securityImpactV2.sections.securityProgress.widgets.orgScore.label' />

      <JitLinearGradientChart customTickFormatter={customTickFormatter} data={normalizedScoreData} gradientColors={gradientColors} />
    </div>
  );
};
