import { FC } from 'react';

type Props = {
  className: string;
  width: string;
  onEnter: () => void;
  onLeave: () => void;
  label: string;
  'data-testid'?: string;
};

export const Bar: FC<Props> = ({ className, width, onEnter, onLeave, label, 'data-testid': testId }) => (
  <div
    aria-label={label}
    className={className}
    data-testid={testId}
    onBlur={onLeave}
    onFocus={onEnter}
    onMouseEnter={onEnter}
    onMouseLeave={onLeave}
    role='button'
    style={{
      width,
      ...(width === '100%' ? { borderRadius: '100px' } : {}),
    }}
    tabIndex={0}
  />
);
