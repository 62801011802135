import { FC } from 'react';
import { NameType, Payload, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import styles from './TooltipFormatter.module.scss';

import { JitGrade } from 'components/JitGrade/JitGrade';
import { JitText } from 'components/JitText/JitText';
import { formatDate } from 'utils';

export const ScoreTooltipFormatter: FC<{ payload?: Payload<ValueType, NameType>[] }> = ({ payload }) => {
  if (!payload?.length) return null;

  const payloadData = payload[0].payload;

  if (payloadData.score === null) return null;

  const date = formatDate(payloadData.date, false);

  return (
    <div className={styles.tooltipWrapper}>
      <JitText muted size='s' text={date} />

      <JitGrade score={payloadData.score} size='s' />
    </div>
  );
};
