import React, { FC, useCallback, useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import styles from './ResourcesTable.module.scss';

import { AppliedFiltersHasNoData } from 'components/JitTable/components/AppliedFiltersHasNoData/AppliedFiltersHasNoData';
import { useGetTableInstance } from 'components/JitTable/hooks/useGetTableInstance';
import { JitTable } from 'components/JitTable/JitTable';
import { JitText } from 'components/JitText/JitText';
import { constants } from 'globalConstants';
import { useGetTableColumns } from 'pages/Resources/ResourcesPage/component/ResourcesTable/useGetTableColumns';
import { useAssetService } from 'services/AssetsService/useAssetService';
import { Queries } from 'types/enums/Queries';
import { IAsset, IFilter, IAggregatedAssetStatistics, AssetSortBy } from 'types/interfaces';

interface Props {
  filters: IFilter[];
  clearFilters: () => void;
  resourcesStatistics: IAggregatedAssetStatistics
}

export const ResourcesTable: FC<Props> = ({ filters, clearFilters, resourcesStatistics }) => {
  const navigate = useNavigate();
  const { getAssetsWithPagination } = useAssetService();
  const { BASE_ROUTE, RESOURCES, RESOURCES_LIST } = constants.routes.risks;

  const SORT_PARAMS = {
    sort_by: AssetSortBy.PriorityScore,
    sort_order: 'desc',
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(
    [Queries.Assets, filters, SORT_PARAMS],
    getAssetsWithPagination,
    {
      getNextPageParam: (response) => response?.metadata.after,
      enabled: !!filters?.length,
    },
  );
  const assets = useMemo(() => data?.pages?.flatMap((page) => page?.data || []) || [], [data?.pages]);

  const onRowClick = useCallback((asset: IAsset) => {
    navigate(`/${BASE_ROUTE}/${RESOURCES}/${RESOURCES_LIST}/${asset.asset_id}`);
  }, [BASE_ROUTE, RESOURCES, RESOURCES_LIST, navigate]);

  const handleReachScrollEnd = useCallback(() => {
    if (hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage, isLoading]);

  const { columns } = useGetTableColumns();
  const tableInstance = useGetTableInstance(columns, assets);

  // eslint-disable-next-line react/no-multi-comp
  const EmptyTableView = React.memo(() => <AppliedFiltersHasNoData clearFilters={clearFilters} entityName='resources' />);

  return (
    <>
      <JitText bold muted params={{ count: resourcesStatistics?.count || 0 }} text='pages.risks.resources.resourcesPage.resourcesCount' />

      <div className={styles.table}>

        <JitTable
          cellPadding='0px 12px'
          className={styles.tableWrapper}
          EmptyTableView={EmptyTableView}
          handleReachScrollEnd={{
            callback: handleReachScrollEnd,
          }}
          headerCellPadding='0'
          isFetching={isFetchingNextPage}
          isLoading={isLoading || !filters.length}
          onSelectRow={onRowClick}
          showPagination={false}
          tableInstance={tableInstance}
        />

      </div>
    </>
  );
};
