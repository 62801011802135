import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { Accessor } from 'react-table';

import { DisabledHeader } from 'components/JitTable/components/DisabledHeader/DisabledHeader';
import { TextCell } from 'components/JitTable/templates';
import { EmptyCell } from 'components/JitTable/templates/EmptyCell/EmptyCell';
import { i18n } from 'locale/i18n';
import { PriorityBox } from 'pages/FindingsPage/components/PriorityBox/PriorityBox';
import { PriorityFactorsCell } from 'pages/Resources/ResourcesPage/component/ResourcesTable/TableCells/PriorityFactorsCell/PriorityFactorsCell';
import { ResourceNameCell } from 'pages/Resources/ResourcesPage/component/ResourcesTable/TableCells/ResorurceNameCell/ResourceNameCell';
import { IAsset, ITableCell } from 'types/interfaces';

interface IColumnHeaders {
  name: string;
  type: string;
  score: string;
  team: string;
  priorityFactors: string;
  findingsByPriorityScore: string;
}

export const useGetTableColumns = () => {
  const { useKnowledgeGraph } = useFlags();
  const { t } = i18n;
  const columnHeaders: IColumnHeaders = useMemo(() => t('pages.risks.resources.resourcesPage.table.columnHeaders', { returnObjects: true }), [t]);

  const columnsData = useMemo(() => {
    const columns = [
      {
        Header: columnHeaders.name,
        Cell: ResourceNameCell,
        width: '30%',
      },
      {
        Header: columnHeaders.type,
        accessor: ((asset: IAsset) => `assetType.${asset.asset_type}`) as Accessor<object>,
        Cell: TextCell,
        width: '16%',
      },
      {
        Header: useKnowledgeGraph ? columnHeaders.score : <DisabledHeader columnKey={columnHeaders.score} />,
        accessor: 'priority_score',
        Cell: useKnowledgeGraph ? ({ cell: { value } }: ITableCell) => (
          <PriorityBox priorityScore={value} />
        ) : EmptyCell,
        width: '10%',
      },
      {
        Header: columnHeaders.team,
        accessor: ((asset: IAsset) => asset.tags.filter((tag) => tag.name === 'team').map((tag) => tag.value).join(', ')) as Accessor<object>,
        Cell: TextCell,
        width: '15%',
      },
      {
        Header: useKnowledgeGraph ? columnHeaders.priorityFactors : <DisabledHeader columnKey={columnHeaders.priorityFactors} />,
        accessor: 'priority_factors',
        Cell: useKnowledgeGraph ? PriorityFactorsCell : EmptyCell,
        width: '34%',
      },
    ];
    if (!useKnowledgeGraph) {
    // Swap team and priority_score columns
      [columns[2], columns[3]] = [columns[3], columns[2]];
    }
    return columns;
  }, [columnHeaders, useKnowledgeGraph]);

  return {
    columns: columnsData,
  };
};
