/* eslint-disable no-nested-ternary */
import { styled } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Coverage } from './components/Coverage/Coverage';
import { DevProductivity } from './components/DevProductivity/DevProductivity';
import { SecurityImpactSubtitle } from './components/SecurityPosture/components/SecurityImpactSubtitle/SecurityImpactSubtitle';
import { SecurityPosture } from './components/SecurityPosture/SecurityPosture';
import { getInsightsSections } from './constants';
import { useFetchMetrics } from './hooks/useFetchMetrics';
import styles from './SecurityImpact.module.scss';

import { JitText } from 'components/JitText/JitText';
import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { PageTitles } from 'components/PageTitles/PageTitles';
import { InsightsCard } from 'pages/OverviewPage/components/Insights/baseComponents';
import { SecurityImpactMetricNames } from 'types/enums/MetricNames';

const StyledInsightsCard = styled(InsightsCard)({
  padding: 24,
  flex: 1,
  overflow: 'hidden',
  height: 314,
});

export const SecurityImpact: FC = () => {
  const navigate = useNavigate();
  const { uiShowNewInsightsPage } = useFlags();

  const { fetchMetrics, metrics, silentFetchDevProductivity } = useFetchMetrics();

  useEffect(() => {
    fetchMetrics();
  }, [navigate, fetchMetrics]);

  const isMetricsLoading = useMemo(() => Object.values(metrics).some((metric) => metric?.isLoading), [metrics]);

  const sections = useMemo(() => getInsightsSections(), []);

  const securityImpactContent = useMemo(() => (
    isMetricsLoading ? <LoadingBar /> : (
      <>
        <SecurityPosture
          devProductivityMetric={metrics[SecurityImpactMetricNames.DevProductivity]}
          openFindingsCount={metrics[SecurityImpactMetricNames.OpenFindingsCount]}
          securityImpactMttrMetric={metrics[SecurityImpactMetricNames.SecurityImpactMTTR]}
          totalFindingsGraphMetric={metrics[SecurityImpactMetricNames.TotalFindingsGraph]}
        />

        <DevProductivity
          devProductivityMetric={metrics[SecurityImpactMetricNames.DevProductivity]}
          silentFetchDevProductivity={silentFetchDevProductivity}
        />

        <Coverage planStatusMetric={metrics[SecurityImpactMetricNames.LayersCoverage]} resourcesCoverageMetric={metrics[SecurityImpactMetricNames.ResourcesCoverage]} />
      </>
    )
  ), [isMetricsLoading, metrics, silentFetchDevProductivity]);

  const securityImpactContentV2 = useMemo(() => (
    <div className={styles.pageContent} data-testid='securityImpactPageContent'>
      {sections.map(({ title, rightTitleElement, content }) => (
        <div key={title} className={styles.section}>
          {title && (
          <div className={styles.sectionTitle}>
            <JitText size='s' text={title} />

            {rightTitleElement}
          </div>
          )}

          <div className={styles.sectionContent}>
            {content.map((widget) => (
              <StyledInsightsCard
                key={widget.title}
                content={widget.component}
                header={{
                  title: widget.title,
                  infoTooltip: widget.infoTooltip,
                }}
                variant='light'
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  ), [sections]);

  return (
    <div className={styles.wrapper} data-testid='securityImpactPage'>
      <div className={styles.title}>
        <PageTitles title={uiShowNewInsightsPage ? 'pages.securityImpactV2.title' : 'pages.securityImpact.title'} />

        {!uiShowNewInsightsPage && <SecurityImpactSubtitle firstDateJitUsage={metrics[SecurityImpactMetricNames.JitUsageFirstDate]} />}
      </div>

      {uiShowNewInsightsPage ? securityImpactContentV2 : securityImpactContent}
    </div>
  );
};
