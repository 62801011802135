import { FixedIssuesWidget } from './components/widgets/FixedIssues/FixedIssuesWidget';
import { IgnoredIssues } from './components/widgets/IgnoredIssues/IgnoredIssues';
import { MostDetectedWidget } from './components/widgets/MostDetectedWidget/MostDetectedWidget';
import { MTTR } from './components/widgets/MTTR/MTTR';
import { OrgScoreWidget } from './components/widgets/OrgScore/OrgScoreWidget';
import { ResolvedIssuesWidget } from './components/widgets/ResolvedIssues/ResolvedIssuesWidget';
import { ResourcesCoverage } from './components/widgets/ResourcesCoverage/ResourcesCoverage';

import { JitDropdownNew } from 'components/JitDropdownNew/JitDropdownNew';
import { JitText } from 'components/JitText/JitText';
import { TimeAgo } from 'types/enums';
import { ServerFindingKeys } from 'types/interfaces';
import { formatDateToRequestFormat, getTimeAgoRange } from 'utils';

type WidgetContent = {
  title: string;
  infoTooltip?: string;
  component: JSX.Element;
};

type Section = {
  title?: string;
  rightTitleElement?: JSX.Element;
  content: WidgetContent[];
};

const ResolvedIssuesQueryParams = {
  group_by: 'resolution',
};

const widgetsPeriod = TimeAgo.ThreeMonths;
const { startDate, endDate } = getTimeAgoRange(widgetsPeriod);

const mostDetectedQueryParams = {
  group_by: ServerFindingKeys.test_name,
  filters: {
    created_at_start: formatDateToRequestFormat(startDate),
    created_at_end: formatDateToRequestFormat(endDate),
  },
};

const fixedIssuesQueryParams = {
  breakdown: 'environment',
};

export const getInsightsSections = (period: TimeAgo = TimeAgo.ThreeMonths): Section[] => [
  {
    title: 'pages.securityImpactV2.sections.securityProgress.title',
    rightTitleElement: <JitText muted size='s' text='pages.securityImpactV2.sections.securityProgress.rightTitleElement' />,
    content: [
      {
        title: 'pages.securityImpactV2.sections.securityProgress.widgets.orgScore.title',
        infoTooltip: 'pages.securityImpactV2.sections.securityProgress.widgets.orgScore.tooltip',
        component: <OrgScoreWidget />,
      },
      {
        title: 'pages.securityImpactV2.sections.securityProgress.widgets.resolvedIssues.title',
        infoTooltip: 'pages.securityImpactV2.sections.securityProgress.widgets.resolvedIssues.tooltip',
        component: <ResolvedIssuesWidget queryParams={ResolvedIssuesQueryParams} />,
      },
      {
        title: 'pages.securityImpactV2.sections.securityProgress.widgets.coveredResources.title',
        component: <ResourcesCoverage filters={{ isCovered: true }} />,
      },
    ],
  },
  {
    title: 'pages.securityImpactV2.sections.performance.title',
    rightTitleElement: <JitDropdownNew
      displayText='pages.securityImpactV2.sections.performance.rightTitleElement.periodsOptions.last90Days'
      isEnabled={false}
      menuItems={[]}
      width={180}
    />,
    content: [
      {
        title: 'pages.securityImpactV2.sections.performance.widgets.mttr.title',
        infoTooltip: 'pages.securityImpactV2.sections.performance.widgets.mttr.tooltip',
        component: <MTTR period={period} />,
      },
      {
        title: 'pages.securityImpactV2.sections.performance.widgets.fixedIssues.title',
        infoTooltip: 'pages.securityImpactV2.sections.performance.widgets.fixedIssues.tooltip',
        component: <FixedIssuesWidget breakdown={fixedIssuesQueryParams.breakdown} period={widgetsPeriod} />,
      },
    ],
  },
  {
    content: [
      {
        title: 'pages.securityImpactV2.sections.performance.widgets.ignoredIssues.title',
        component: <IgnoredIssues period={period} />,
      },
      {
        title: 'pages.securityImpactV2.sections.performance.widgets.mostDetectedFindings.title',
        infoTooltip: 'pages.securityImpactV2.sections.performance.widgets.mostDetectedFindings.tooltip',
        component: <MostDetectedWidget period={widgetsPeriod} queryParams={mostDetectedQueryParams} />,
      },
    ],
  },
];
